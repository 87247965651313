import React, { useEffect, useState } from 'react';
import { styles } from '../org-serviceline/org-servicelines.styles';
import {
  PopoverBody,
  Stack,
  Box,
  Flex,
  Text,
  PopoverContent,
  Portal,
  Divider
} from '@chakra-ui/react';
import { BiomeIcon, IconName } from '@codametrix/verdigris';
import { DashboardSelectionSubNav } from './dashboard-selection-subnav';

type DashBoardSelectProps = {
  dashboardSelectionOpened: boolean;
  activeDashboard: string;
  handleDashboardSelection: (boardName: string, dashboardPath: string) => void;
  resources: CMxAPI.Resource[];
  dashboardPaths: { [key: string]: string };
};

export const DashboardSelection: React.FC<DashBoardSelectProps> = ({
  dashboardSelectionOpened,
  activeDashboard,
  resources,
  handleDashboardSelection,
  dashboardPaths,
  ...props
}) => {
  const classes = styles();

  const tableauDashboardResourceId = resources.find(
    resource => resource.resourceName === 'tableau_dashboard'
  )?.resourceId;
  const sections = resources.filter(
    resource => resource.parentId === tableauDashboardResourceId
  );
  let dashboardOptions: {
    [resourceName: string]: {
      name: string;
      dashboards: { dashboardName: string; dashboardResource: string }[];
    };
  } = {};
  if (sections) {
    sections.forEach(section => {
      const dashboardResources = resources.filter(
        resource =>
          resource.parentId === section.resourceId &&
          Object.keys(dashboardPaths).includes(resource.resourceName)
      );
      dashboardOptions[section.resourceName] = {
        name: JSON.parse(section.metadata).label,
        dashboards: dashboardResources.map(resource => ({
          dashboardName: resource.label,
          dashboardResource: resource.resourceName
        }))
      };
    });
  }

  const defaultDashboard = Object.values(dashboardOptions)[0].dashboards[0];

  useEffect(() => {
    if (!activeDashboard) {
      handleDashboardSelection(
        defaultDashboard.dashboardName,
        defaultDashboard.dashboardResource
      );
    }
  });

  let [activeDashboardName, setActiveDashboardName] = useState<string>(
    activeDashboard?.length
      ? activeDashboard
      : defaultDashboard.dashboardName.split(' ')[0].toLowerCase()
  );

  let [activeDashboardSection, setActiveDashboardSection] = useState<
    CMxAPI.Resource
  >(sections[0]);

  useEffect(() => {
    if (activeDashboard?.length > 0) {
      setActiveDashboardName(activeDashboard);
    }
  }, [activeDashboard]);

  const handleSectionSelection = (section: CMxAPI.Resource) => {
    setActiveDashboardSection(section);
    setActiveDashboardName(
      dashboardOptions[section.resourceName].dashboards[0].dashboardName
        .split(' ')[0]
        .toLowerCase()
    );
  };

  return (
    <Portal>
      <Flex className="dashboardSelection" data-testid="dashboardSelection">
        <PopoverContent sx={classes['.mainGrid']} className="popOverStyle">
          <PopoverBody sx={classes['.bodyGrid']}>
            <Stack direction="row">
              {sections.length > 1 ? (
                <Box sx={classes['.popoverInsightsBox']}>
                  {dashboardSelectionOpened &&
                    sections.map(section => {
                      const dashboardSubsection =
                        dashboardOptions[section.resourceName];
                      const { name } = dashboardSubsection;
                      return (
                        <Flex
                          sx={classes['.popoverHeader']}
                          key={name + 'header'}
                          flexDirection="column">
                          <Flex
                            sx={classes['.switchItem']}
                            key={name}
                            className={`hoverCursor ${
                              activeDashboardSection.resourceName ===
                              section.resourceName
                                ? 'selectedItem'
                                : ''
                            }`}
                            onClick={() => handleSectionSelection(section)}>
                            <Text>{name}</Text>
                            <Flex sx={classes['.switchArrow']}>
                              <Flex sx={classes['.iconBox']}>
                                <BiomeIcon
                                  iconName={IconName.Chevron_Right}
                                  iconSize="16"
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      );
                    })}
                </Box>
              ) : (
                dashboardSelectionOpened && (
                  <DashboardSelectionSubNav
                    activeDashboardOptions={
                      dashboardOptions[activeDashboardSection.resourceName]
                    }
                    activeDashboardName={activeDashboardName}
                    handleDashboardSelection={handleDashboardSelection}
                  />
                )
              )}
              {sections.length > 1 ? (
                <Flex sx={classes['.subnavContainer']}>
                  <Divider
                    orientation="vertical"
                    sx={classes['.subnavDivider']}
                  />
                  <DashboardSelectionSubNav
                    activeDashboardOptions={
                      dashboardOptions[activeDashboardSection.resourceName]
                    }
                    activeDashboardName={activeDashboardName}
                    handleDashboardSelection={handleDashboardSelection}
                  />
                </Flex>
              ) : null}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Flex>
    </Portal>
  );
};
