/// <reference path="../types.d.ts" />

import InitialState from '../reducers/initial-state';
import * as analytics from '../actions/analytics';
import { refreshContext } from '../actions/contexts';
import { LOAD_STATES } from './common';
import { configAvailable } from '../actions/ui';
import { cmxReducerFactory } from './reducer-utils';

const reducer = cmxReducerFactory(InitialState.analytics)
  .case(analytics.instantiateDashboard, (state, params) => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(configAvailable, (state, userConfig) => {
    const { tableauInfo } = userConfig;
    return {
      ...state,
      tableauInfo
    };
  })
  .cases(
    [refreshContext.async.started, analytics.fetchTableauToken.async.started],
    state => {
      return {
        ...state
      };
    }
  )
  .cases([analytics.fetchTableauToken.async.failed], (state, loaded) => {
    return {
      ...state,
      loadState: LOAD_STATES.failed
    };
  })
  .case(
    analytics.fetchTableauToken.async.done,
    (state, { result: tableauToken }) => {
      return {
        ...state,
        tableauToken
      };
    }
  )
  .case(analytics.setActiveDashboard, (state, boardName) => {
    return {
      ...state,
      activeDashboard: boardName
    };
  })
  .case(analytics.setActiveDashboardResource, (state, dashboardResource) => {
    return {
      ...state,
      activeDashboardResource: dashboardResource
    };
  })
  .case(
    analytics.getSubNavPaths.async.done,
    (state, { result: configurationValues }) => {
      const dashboardPaths: { [key: string]: string } = {};
      configurationValues.sort((a, b) => a.id - b.id);

      let activeDashboardResource: string | undefined = undefined;

      configurationValues.forEach(configVal => {
        if (!activeDashboardResource && configurationValues.length !== 1) {
          activeDashboardResource = configVal.values[0].configurationKeyname;
        }

        if (configVal.values?.length) {
          dashboardPaths[configVal.values[0].configurationKeyname] =
            configVal.values[0].value;
        }
      });
      return {
        ...state,
        dashboardPaths: dashboardPaths,
        activeDashboardResource:
          configurationValues[0].values[0].configurationKeyname
      };
    }
  );

export default reducer;
