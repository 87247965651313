import React, { useEffect, useRef, useState } from 'react';
import * as userUtils from '../../core/utils/user-utils';
import { Avatar } from '@chakra-ui/react';
import { cmxDateTime } from '@codametrix/ui-common';
import './coder-note-card.scss';
import { Icon } from '@codametrix/ui-components';
import { Conditional, Confirm } from '@codametrix/shared-views';
import { objectUtils } from '@codametrix/ui-common';
import { Textarea } from '../../components/textarea/textarea';
import { CoderNoteEditProps, ConfirmModalProps } from './coder-notes';

export type CoderNoteCardProps = {
  note: AmplifyAPI.CoderNoteRow;
  activeUser: CMxAPI.User;
  editMode: CoderNoteEditProps;
  toggleEditMode: (toggle: boolean, noteId?: string) => void;
  handleDeleteCoderNotes: (note: AmplifyAPI.CoderNoteRow) => void;
  handleEditCoderNotes: (note: AmplifyAPI.CoderNoteRow) => void;
};

export const CoderNoteCard: React.FC<CoderNoteCardProps> = ({
  note,
  activeUser,
  editMode,
  toggleEditMode,
  handleDeleteCoderNotes,
  handleEditCoderNotes
}) => {
  /** State variable to manage edit mode is toggle */
  const [isEditEnbabled, setIsEditEnbabled] = useState(
    editMode.isEditEnabled && editMode.noteId === note.note_id
  );

  /** State variable to manage  Confirm dialog, to be shown on cancel of edit mode*/
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    enabled: false,
    title: '',
    proceedLabel: '',
    dismissLabel: ''
  });

  /** State variable to capture updated note content */
  const [coderNoteContent, setCoderNoteContent] = useState(note.note_content);

  /** Update isEditEnabled upon change in editMode value from parent(Coder Note) */
  useEffect(() => {
    setIsEditEnbabled(
      editMode.isEditEnabled && editMode.noteId === note.note_id
    );
  }, [editMode, note]);

  const userAvatarInitials = userUtils.getAvatarInitials(
    note.coder_firstname,
    note.coder_lastname
  );
  const userAvatar = (
    /* @ts-ignore */
    <Avatar
      name={userAvatarInitials}
      bgColor="#6B8D99"
      color="#FFFFFF"
      size="sm"
    />
  );

  /** Edited label should be visible on timeline, when the note is edited(modified_date is not null)*/
  const isEditedNote = !objectUtils.isEmpty(note.modified_date);
  const noteTimestamp = isEditedNote ? note.modified_date : note.created_date;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  /** Handle click on delete note */
  const deleteCoderNote = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleDeleteCoderNotes(note);
  };

  /** Handle click edit note and enable edit mode for the note */
  const handleEditClick = (e: React.MouseEvent<HTMLElement>) => {
    toggleEditMode(true, note.note_id);
  };

  /** Handle cancel click during edit mode of note */
  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (note.note_content !== coderNoteContent) {
      /** Confirm dialog in case content is changed */
      setConfirmModal({
        enabled: true,
        title: 'Are you sure you want to disard changes ?',
        dismissLabel: 'No',
        proceedLabel: 'Yes'
      });
    } else {
      /** In case content is not changes disable edit mode and reset note content */
      toggleEditMode(false);
      setCoderNoteContent(note.note_content);
    }
  };

  /** Hnadler for click on save note during edit mode */
  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    toggleEditMode(true, note.note_id);
    handleEditCoderNotes({
      ...note,
      note_content: coderNoteContent
    });
  };

  /** Update codernote state var with change in text area content */
  const handleCoderNoteChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    let inputValue = e.target.value;
    setCoderNoteContent(inputValue);
  };

  /** Click on Esc should disable edit mode*/
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      toggleEditMode(false);
      setCoderNoteContent(note.note_content);
    }
  };

  /** Yes on Confirm dialog should disacrd edit mode and reset content */
  const handleConfirm = () => {
    setCoderNoteContent(note.note_content);
    toggleEditMode(false);
    resetConfirmModal();
  };

  /** Method to reset confirm dialog state */
  const resetConfirmModal = () => {
    setConfirmModal({
      enabled: false,
      title: '',
      proceedLabel: '',
      dismissLabel: ''
    });
  };

  return (
    /* @ts-ignore */
    <div onKeyDown={handleKeyDown}>
      <div data-testid="coder-note-card" className="coder-note-card">
        {userAvatar}
        <Conditional
          on={!isEditEnbabled}
          style={{ width: '100%', display: isEditEnbabled ? 'none' : 'block' }}>
          <div className="note-container">
            <>
              <div data-testid="note-content" className="note-content">
                {note.note_content}
                {/** Action options shown if the note is created by the current active user*/}
                <Conditional
                  every={[activeUser.username === note.coder_username]}>
                  <div className="note-actions">
                    <div onClick={e => handleEditClick(e)}>
                      <Icon
                        svgIconName="BiomeEdit"
                        svgIconSize="20"
                        svgIconClass="action-icon edit-icon"
                      />
                    </div>
                    <div onClick={e => deleteCoderNote(e)}>
                      <Icon
                        svgIconName="BiomeDelete"
                        svgIconSize="20"
                        svgIconClass="action-icon delete-icon"
                      />
                    </div>
                  </div>
                </Conditional>
              </div>
              <div className="note-timeline">
                {noteTimestamp
                  ? cmxDateTime.format(
                      noteTimestamp,
                      cmxDateTime.FORMATS.LONG_DATE_TIME
                    )
                  : 'N/A'}{' '}
                | {note.coder_fullName} {isEditedNote ? ' | Edited' : ''}
              </div>
            </>
          </div>
        </Conditional>
        <Conditional
          on={isEditEnbabled}
          style={{ width: '100%', display: isEditEnbabled ? 'block' : 'none' }}>
          <div className="coder-notes-edit">
            <Textarea
              ref={textAreaRef}
              placeholder="Add Note"
              value={coderNoteContent}
              onChange={handleCoderNoteChange}
            />
            <div className="coder-note-actions">
              <button
                onClick={ev => {
                  handleCancel(ev);
                }}
                className="chakra-secondary-button">
                Cancel
              </button>
              <button
                onClick={ev => handleSave(ev)}
                className={`chakra-primary-button ${
                  objectUtils.isEmpty(coderNoteContent) ? 'disabled' : ''
                }`}>
                Save
              </button>
            </div>
          </div>
        </Conditional>
      </div>
      <Confirm
        onConfirm={handleConfirm}
        onDismiss={resetConfirmModal}
        display={confirmModal.enabled}
        strings={{
          proceed: confirmModal.proceedLabel,
          dismiss: confirmModal.dismissLabel
        }}
        title={confirmModal.title}
      />
    </div>
  );
};

CoderNoteCard.defaultProps = {};

CoderNoteCard.displayName = 'CoderNoteCard';
