// import libraries
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

// import components
import { Button } from '../button/button';
import { ButtonVariant } from '../../core/enums';
import { GenericRelatedModal } from '../generic-related-modal/generic-related-modal';
import {
  mergeCasesActions,
  AmplifyCore,
  commonEnums as enums
} from '@codametrix/ui-common';
import { DataGrid } from '@codametrix/ui-components';
import { Checkbox } from '../../components/checkbox/checkbox';
import { CMXIcon, Conditional, getCellData } from '@codametrix/shared-views';
import { LoadingSlugs } from '@codametrix/ui-components';
import {
  showFeedbackError,
  showFeedbackSuccess,
  showFeedbackWarning
} from '../../actions/ui';

// import styles
import theme from '../../theme';
import './merge-cases-modal.scss';
import { Action } from 'redux';

const { benchEditable: benchEditableCore } = AmplifyCore;

/**
 * Definitions for columns in the mergeable cases grid.
 * @constant
 * @type {Array<Object>}
 */
const mergeablecolsDefs = [
  {
    key: 'case_id',
    displayName: `CMX ID`,
    isSortable: false
  },
  {
    key: 'date_of_service',
    displayName: `Service date`,
    isSortable: false
  },
  {
    key: 'case_status',
    displayName: `Status`,
    isSortable: false,
    transform: (caseRun: CMxAPI.CaseRunV2) => {
      const styleClasses = benchEditableCore.fecthProcessStatusClasses(
        caseRun?.processStatus,
        caseRun?.coding_status?.hold_status
      );

      const celldata = getCellData(caseRun.processStatus, false);
      return <span className={classnames(styleClasses)}>{celldata}</span>;
    }
  },
  {
    key: 'patient_class',
    displayName: `Patient class`,
    isSortable: false
  },
  {
    key: 'note_specialty_designation',
    displayName: `Note specialty`,
    isSortable: false
  },
  {
    key: 'note_type',
    displayName: `Note type`,
    isSortable: false
  },
  {
    key: 'primary_activity_provider_name',
    displayName: `Authoring provider`,
    isSortable: false
  },
  {
    key: 'note_final_sign_provider_name',
    displayName: `Final sign provider`,
    isSortable: false
  }
];

/**
 * MergeCasesModal functional component.
 * @component
 * @returns {React.ReactNode} - The rendered component.
 */
export const MergeCasesModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isOpen,
    currentCaseRun,
    activeProcessInstanceGuid,
    activeNotes,
    loadingNotes,
    loadingMerging,
    loadingMergeableCases,
    mergingResult,
    mergeableCaseRuns,
    cursorAPIContext
  } = useSelector((state: CMx.ApplicationState) => {
    return state.mergeCases;
  });

  const hasAmplifyCaseRunV2Access = useSelector(
    (state: CMx.ApplicationState) =>
      state.ui.context.activeContext?.resources?.some(
        ({ resourceName }) =>
          resourceName === enums.ResourceId.AMPLIFY_CASE_RUN_V2
      ) || false
  );

  /**
   * Close the merge cases modal.
   * @function
   */
  const handleClose = () => {
    dispatch(mergeCasesActions.hideMergeCasesModal());
  };

  /**
   * Merge selected cases.
   * @function
   */
  const handleMergeCases = async () => {
    const selectedMergeableCases = mergeableCaseRuns.filter(
      mergeCase => mergeCase.selected
    );

    if (selectedMergeableCases.length >= 1) {
      try {
        await dispatch(
          (mergeCasesActions.requestMergeCases({
            currentCaseRun,
            casesToMerge: [currentCaseRun, ...selectedMergeableCases],
            cursorAPIContext,
            hasAmplifyCaseRunV2Access
          }) as any) as Action
        );
      } catch (error) {
        dispatch(
          showFeedbackError({
            message: 'There were issues merging cases.',
            dismissable: true,
            className: 'feedback-error'
          })
        );
      }
    } else {
      dispatch(
        showFeedbackWarning({
          message: 'Merge cases needs a minimum of two cases.',
          dismissable: true,
          className: 'feedback-error'
        })
      );
    }
  };

  const checkBoxMergeable = {
    key: 'check_mergeable',
    displayName: ``,
    isSortable: false,
    transform: (caseRun: CMxAPI.CaseRunV2) => {
      return (
        <Checkbox
          className="chk-mergeable"
          iconColor={theme.colors.opacity.primary}
          checkboxColor={theme.colors.accent[100]}
          textColor={theme.colors.text['mediumEmphasis']}
          isChecked={caseRun?.selected}
        />
      );
    }
  };

  /**
   * Fetch mergeable cases and fetch documents for the current case
   * @function
   */
  useEffect(() => {
    if (isOpen) {
      dispatch(
        (mergeCasesActions.fetchMergeableCases({
          encounterId: currentCaseRun?.encounter_identifier?.number,
          serviceLine: currentCaseRun?.service_line
        }) as any) as Action
      );
      dispatch(
        (mergeCasesActions.getDocumentsByCaseRun(
          currentCaseRun
        ) as any) as Action
      );
    }

    // eslint-disable-next-line
  }, [isOpen]);

  /**
   * Show success message when merge process finishes
   * @function
   */
  useEffect(() => {
    if (mergingResult) {
      dispatch(
        showFeedbackSuccess({
          message:
            'The cases have been merged, navigating to the new merged case...',
          dismissable: true,
          className: 'feedback-success'
        })
      );
    }

    // eslint-disable-next-line
  }, [mergingResult]);

  /**
   * Handle selection of mergeable cases checkbox.
   * @function
   * @param {any} data - The data associated with the selected row.
   * @param {any} ev - The event object.
   */
  const handleMergeableCaseSelection = (data: any, ev: any) => {
    const chk = (ev.target as HTMLElement)?.closest('.chk-mergeable');

    if (chk) {
      dispatch(
        mergeCasesActions.toggleMergeableCaseSelection(
          data.process_instance_guid
        )
      );
    } else {
      dispatch(
        (mergeCasesActions.getDocumentsByCaseRun(data) as any) as Action
      );
      dispatch(mergeCasesActions.setActiveCaseRun(data.process_instance_guid));
    }
  };

  return (
    <>
      <GenericRelatedModal
        data-test-id="merge-cases-modal"
        className="merge-cases-modal"
        isOpen={isOpen}
        handleClose={handleClose}
        showCloseIcon={true}
        includePatientEncounter={true}
        activeCaseRun={currentCaseRun}
        notes={activeNotes}
        loadingNotes={loadingNotes}
        modalHeader={<p>Merge Cases</p>}
        modalContent={
          <>
            <div className="grid-title-section">
              <h2 className="grid-title">Primary Case</h2>
              <p className="grid-content">
                <div className="case-alert-icon-info">
                  <CMXIcon
                    svgTitleText="As the primary case, these fields values will be preserved when merged with cases that have conflicting field values."
                    svgIconName="BiomeInfo"
                    svgIconSize="20"
                    svgIconClass={''}
                  />
                </div>
              </p>
            </div>
            <DataGrid
              idField="process_instance_guid"
              items={[currentCaseRun]}
              idSelected={activeProcessInstanceGuid ?? ''}
              columnDefs={mergeablecolsDefs}
              selectable={true}
              theme={'chakra'}
              onRowSelection={handleMergeableCaseSelection}
            />

            <Conditional every={[mergeableCaseRuns.length > 0]}>
              <>
                <div className="grid-title-section">
                  <h2 className="grid-title">Mergeable cases</h2>
                  <p className="grid-content">
                    <div className="case-alert-icon-info">
                      <CMXIcon
                        svgTitleText="A case is mergeable if it meets the following criteria: the case is chargeable, is in a codeable status, it has not previously been merged into another case, and is not the result of other merged cases."
                        svgIconName="BiomeInfo"
                        svgIconSize="20"
                        svgIconClass={''}
                      />
                    </div>
                  </p>
                </div>
                <DataGrid
                  idField="process_instance_guid"
                  idSelected={activeProcessInstanceGuid ?? ''}
                  items={mergeableCaseRuns}
                  columnDefs={[checkBoxMergeable, ...mergeablecolsDefs]}
                  selectable={true}
                  theme={'chakra'}
                  onRowSelection={handleMergeableCaseSelection}
                />
              </>
            </Conditional>

            <Conditional
              every={[mergeableCaseRuns.length === 0, !loadingMergeableCases]}>
              <div className="note-unavaialable">
                There are no other cases eligible for merge
              </div>
            </Conditional>

            <Conditional every={[loadingMergeableCases]}>
              <LoadingSlugs numberItems={7} />
            </Conditional>
          </>
        }
        modalFooter={
          <div className="action-buttons">
            <Button
              variant={ButtonVariant.SECONDARY}
              dataTestId="cancelBtn"
              label="Cancel"
              onClick={handleClose}
            />

            <Button
              dataTestId="mergeBtn"
              className="merge-btn"
              disabled={mergeableCaseRuns.length === 0 || mergingResult}
              isLoading={loadingMerging}
              label="Merge"
              onClick={handleMergeCases}
            />
          </div>
        }
      />
    </>
  );
};
