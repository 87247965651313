import { objectUtils } from '@codametrix/ui-common';

export const getAvatarInitialsByFullName = (fullName: string) => {
  if (objectUtils.isEmpty(fullName)) {
    return 'N/A';
  }
  // Split the full name by comma
  var parts = fullName.split(',');
  // Extract the first and last name
  var lastName = parts[0].trim();
  var firstName = (parts[1] || '').trim();
  // Get the initials
  var initials =
    firstName.charAt(0).toUpperCase() +
    (lastName && !objectUtils.isEmpty(lastName)
      ? ` ${lastName.charAt(0).toUpperCase()}`
      : '');
  return initials;
};

export const getAvatarInitials = (firstName: string, lastName: string) => {
  if (objectUtils.isEmpty(firstName) && objectUtils.isEmpty(lastName)) {
    return 'N/A';
  }
  // Get the initials
  var initials =
    firstName.charAt(0).toUpperCase() +
    (lastName && !objectUtils.isEmpty(lastName)
      ? ` ${lastName.charAt(0).toUpperCase()}`
      : '');
  return initials;
};
